<template>
  <div
    class="modebase"
    ref="modebase"
    style="padding-top: 50px; padding-bottom: 50px"
  >
    <el-row
      class="shaixuan"
      style="
        width: 1370px;
        margin: 0 auto;
        padding: 0px 0 0;
        border: 1px solid #cccccc;
        border-bottom: none;
      "
    >
      <el-row :span="24" v-show="false">
        <el-col :span="2">大库：</el-col>
        <el-col :span="22">
          <el-link
            v-for="(level_0s, index) in level_0"
            style="margin-right: 30px"
            @click="
              click_link(level_0s.id, 1, level_0s.node_id, level_0s.sequence)
            "
            :data-pnode="level_0s.parentnodeid"
            :data-level="level_0s.level"
            :data-id="level_0s.id"
            v-bind:class="{ choose: ifshow(level_0s.id, 1) }"
            :data-nodeid="level_0s.node_id"
            :data-sequence="level_0s.sequence"
            >{{ level_0s.name }}</el-link
          >
        </el-col>
      </el-row>
      <el-row :span="24" v-if="level_1.length != 0" style="padding: 0 15px">
        <el-col :span="2">{{ catalog2[0] }}：</el-col>
        <el-col :span="22">
          <el-link
            v-for="(level_1s, index) in level_1" :key="index" v-show="level_1s.is_jiaodi !=1 "
            style="margin-right: 30px"
            @click="
              click_data(
                level_1s.id,
                2,
                level_1s.node_id,
                level_1s.sequence,
                level_1s.parent_node_id
              )
            "
            :data-pnode="level_1s.parentnodeid"
            :data-level="level_1s.level"
            :data-id="level_1s.id"
            v-bind:class="{ choose: ifshow(level_1s.id, 2) }"
            :data-nodeid="level_1s.node_id"
            :data-sequence="level_1s.sequence"
            >{{ level_1s.name }}</el-link
          >
        </el-col>
      </el-row>
      <el-row :span="24" v-if="level_2.length != 0" style="padding: 0 15px">
        <el-col :span="2">{{ catalog2[1] }}：</el-col>
        <el-col :span="22">
          <el-link
            v-for="(level_2s, index) in level_2"
            style="margin-right: 30px"
            @click="
              click_data(
                level_2s.id,
                3,
                level_2s.node_id,
                level_2s.sequence,
                level_2s.parent_node_id
              )
            "
            :data-level="level_2s.level"
            :data-id="level_2s.id"
            v-bind:class="{ choose: ifshow(level_2s.id, 3) }"
            :data-nodeid="level_2s.node_id"
            :data-sequence="level_2s.sequence"
            >{{ level_2s.name }}</el-link
          >
        </el-col>
      </el-row>
      <el-row :span="24" v-if="level_3.length != 0" style="padding: 0 15px">
        <el-col :span="2">{{ catalog2[2] }}：</el-col>
        <el-col :span="22">
          <el-link
            v-for="(level_3s, index) in level_3"
            style="margin-right: 30px"
            @click="
              click_data(
                level_3s.id,
                4,
                level_3s.node_id,
                level_3s.sequence,
                level_3s.parent_node_id
              )
            "
            :data-level="level_3s.level"
            :data-id="level_3s.id"
            v-bind:class="{ choose: ifshow(level_3s.id, 4) }"
            :data-nodeid="level_3s.node_id"
            :data-sequence="level_3s.sequence"
            >{{ level_3s.name }}</el-link
          >
        </el-col>
      </el-row>
      <el-row :span="24" v-if="level_4.length != 0" style="padding: 0 15px">
        <el-col :span="2">{{ catalog2[3] }}：</el-col>
        <el-col :span="22">
          <el-link
            v-for="(level_4s, index) in level_4"
            style="margin-right: 30px"
            @click="
              click_data(
                level_4s.id,
                5,
                level_4s.node_id,
                level_4s.sequence,
                level_4s.parent_node_id
              )
            "
            :data-level="level_4s.level"
            :data-id="level_4s.id"
            v-bind:class="{ choose: ifshow(level_4s.id, 5) }"
            :data-nodeid="level_4s.node_id"
            :data-sequence="level_4s.sequence"
            >{{ level_4s.name }}</el-link
          >
        </el-col>
      </el-row>
      <el-row :span="24" v-if="level_5.length != 0" style="padding: 0 15px">
        <el-col :span="2">{{ catalog2[4] }}：</el-col>
        <el-col :span="22">
          <el-link
            v-for="(level_5s, index) in level_5"
            style="margin-right: 30px"
            @click="
              click_data(
                level_5s.id,
                6,
                level_5s.node_id,
                level_5s.sequence,
                level_5s.parent_node_id
              )
            "
            :data-level="level_5s.level"
            :data-id="level_5s.id"
            v-bind:class="{ choose: ifshow(level_5s.id, 6) }"
            :data-nodeid="level_5s.node_id"
            :data-sequence="level_5s.sequence"
            >{{ level_5s.name }}</el-link
          >
        </el-col>
      </el-row>
    </el-row>
    <el-row
      class=""
      style="
        background: #fff;
        width: 1370px;
        margin: 10px auto;
        height: 50px;
        line-height: 50px;
      "
    >
      <el-col style="width: 16%">
        <!-- <el-checkbox-group v-model="version">
          <el-checkbox label="1" key="1" @change="handleChange"
            >3.0</el-checkbox
          >
          <el-checkbox label="2" key="2" @change="handleChange"
            >5.0</el-checkbox
          >
        </el-checkbox-group> -->

        <!-- <el-checkbox label="1" key="1" v-model="version">3.0</el-checkbox>
        <el-checkbox label="2" key="2" v-model="version">5.0</el-checkbox> -->
        <el-radio v-model="version" label="0">全部</el-radio>
        <!-- <el-radio v-model="version" label="1">5.0</el-radio>
        <el-radio v-model="version" label="2">6.0</el-radio> -->
      </el-col>

      <el-col style="width: 17%">
        <el-radio v-model="radio" label="1">全部</el-radio>
        <el-radio v-model="radio" label="2">免费</el-radio>
        <el-radio v-model="radio" label="3">金币</el-radio>
      </el-col>

      <el-col style="font-size: 14px; width: 33.5%">
        <el-radio v-model="conditiontwo" label="0" :disabled="shaixuandonghua"
          >全部</el-radio
        >
        <el-radio v-model="conditiontwo" label="1" :disabled="shaixuandonghua"
          >内置动画</el-radio
        >
        <el-radio v-model="conditiontwo" label="2" :disabled="shaixuandonghua"
          >自定义动画</el-radio
        >
        <el-radio v-model="conditiontwo" label="3" :disabled="shaixuandonghua"
          >工具</el-radio
        >
        <el-radio v-model="conditiontwo" label="4" :disabled="shaixuandonghua"
          >logo</el-radio
        >
      </el-col>
      <el-col :span="2" style="font-size: 15px; text-align: center; width: 5%">
        排序：
      </el-col>
      <el-col :span="5" style="width: 20%">
        <el-col :span="6">
          <div
            @click="sort(price, 1)"
            v-bind:class="{ active_sort: isSelect == 1 }"
            style="cursor: pointer; color: #b6492c; font-size: 14px"
          >
            价格 <i v-show="price == 10" class="el-icon-bottom"></i
            ><i v-show="price == 11" class="el-icon-top"></i>
          </div>
        </el-col>
        <el-col :span="6">
          <div
            @click="sort(downloads, 2)"
            v-bind:class="{ active_sort: isSelect == 2 }"
            style="cursor: pointer; color: #b6492c; font-size: 14px"
          >
            下载量<i v-show="downloads == 20" class="el-icon-bottom"></i
            ><i v-show="downloads == 21" class="el-icon-top"></i>
          </div>
        </el-col>
        <el-col :span="6">
          <div
            @click="sort(browse, 3)"
            v-bind:class="{ active_sort: isSelect == 3 }"
            style="cursor: pointer; color: #b6492c; font-size: 14px"
          >
            浏览量<i v-show="browse == 30" class="el-icon-bottom"></i
            ><i v-show="browse == 31" class="el-icon-top"></i>
          </div>
        </el-col>
        <el-col :span="6">
          <div
            @click="sort(dianzan, 4)"
            v-bind:class="{ active_sort: isSelect == 4 }"
            style="cursor: pointer; color: #b6492c; font-size: 14px"
          >
            点赞量<i v-show="dianzan == 40" class="el-icon-bottom"></i
            ><i v-show="dianzan == 41" class="el-icon-top"></i>
          </div>
        </el-col>
      </el-col>
      <el-col :span="2" style="font-size: 14px; text-align: center">
        共 {{ total }} 个资源
      </el-col>
    </el-row>
    <el-row
      class="jieguo"
      :gutter="20"
      style="width: 1380px; margin: 0px auto; color: #ccc; font-size: 14px"
    >
      <el-col
        style="
          height: 400px;
          line-height: 400px;
          text-align: center;
          color: #000000;
          padding: 15px;
        "
        v-if="goodsData.length == 0"
      >
        <el-col style="background: #ffffff"> 暂无数据 </el-col>
      </el-col>
      <el-col v-for="(goodsData1, index) in goodsData" class="hoverbox">
        <el-col style="" class="box">
          <el-col
            :span="24"
            style="background: #e6e6e6; overflow: hidden; position: relative"
            class="box_img"
          >
            <div style="position: absolute ;display:flex;top:8px">
              <p v-show="goodsData1.type_id == 0" style="width: 20px">
                <img
                  src="../assets/img/UserIcon.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="goodsData1.is_ci == 1" style="width: 20px">
                <img
                  src="../assets/img/qiyeIcon.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p
                v-show="goodsData1.type_id == 1 && goodsData1.is_ci != 1"
                style="width: 20px"
              >
                <img
                  src="../assets/img/guanfnagIcom.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="goodsData1.animation == 1" style="width: 20px;margin-left:4px">
                <img
                  src="../assets/img/CustomAnimation.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="goodsData1.map == 1" style="width: 20px;margin-left:4px">
                <img
                  src="../assets/img/BuiltAnimation.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="goodsData1.plugin == 1" style="width: 20px;margin-left:4px">
                <img
                  src="../assets/img/CustomModel.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="goodsData1.is_customlogo == 1" style="width: 20px;margin-left:4px">
                <img
                  src="../assets/img/logozz.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
            </div>
            <div
              style="position: absolute; left: 190px; top: 6px"
              v-show="!shaixuandonghua"
            >
              <!-- <p style="width: 60px">
                <img
                  src="../assets/img/san_05.png"
                  alt=""
                  style="width: 19px"
                  v-show="
                    goodsData1.version_type == '1' ||
                    goodsData1.version_type == '3'
                  "
                /><img
                  src="../assets/img/san_06.png"
                  alt=""
                  style="width: 19px; margin-left: 6px"
                  v-show="
                    goodsData1.version_type == '2' ||
                    goodsData1.version_type == '3'
                  "
                />
              </p> -->

              <!-- <p v-show="conditiontwo == '1'" style="width: 20px">
                <img
                  src="../assets/img/neizhidonghua.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="conditiontwo == '3'" style="width: 20px">
                <img
                  src="../assets/img/zidingyigongju.png"
                  alt=""
                  style="width: 100%"
                />
              </p>
              <p v-show="conditiontwo == '2'" style="width: 20px">
                <img
                  src="../assets/img/zidingyidonghua.png"
                  alt=""
                  style="width: 100%"
                />
              </p> -->
            </div>
            <div class="imgbox">
              <img
                :src="common.retun_img(goodsData1.thumbnail)"
                style="width: 80%; margin: 0 auto; display: block"
                oncontextmenu="return false;"
                @click="toDetail(goodsData1.id)"
              />
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="goodsData1.webfabulous == 1 ? '取消点赞' : '点赞'"
              placement="top"
            >
              <div
                style="
                  position: absolute;
                  right: 6px;
                  top: 6px;
                  cursor: pointer;
                "
                @click="fabulous(goodsData1)"
              >
                <!-- <p style="color: #B0472C;margin:4px;font-size: 12px;font-weight: bold;">
                  <span v-show="
                    goodsData1.version_type == '1' ||
                    goodsData1.version_type == '3'
                  ">5.0</span>
                  <span style="margin-left: 6px" v-show="
                    goodsData1.version_type == '2' ||
                    goodsData1.version_type == '3'
                  ">6.0</span>
                </p> -->
                <!-- <p style="font-size: 12px; color: #b6b6b6; text-align: center">
                  {{ goodsData1.fabulous }}
                </p> -->
              </div>
            </el-tooltip>
            <div
              style=""
              class="wendang"
              v-show="
                goodsData1.relation_resourceid != null &&
                goodsData1.relation_resourceid != 0 &&
                goodsData1.resource_type == 2
              "
              @click="toDetail(goodsData1.relation_resourceid)"
            >
              <p style="width: 30px"></p>
            </div>
            <div
              style=""
              class="donghua"
              v-show="
                goodsData1.relation_resourceid != null &&
                goodsData1.relation_resourceid != 0 &&
                goodsData1.resource_type == 3
              "
              @click="toDetail(goodsData1.relation_resourceid)"
            >
              <p style="width: 30px"></p>
            </div>
          </el-col>
          <el-col class="listbuttom_data">
            <div :title="goodsData1.name" class="list_top_data">
              <span style="font-size: 16px; color: #000000" class="text_over">{{
                goodsData1.name
              }}</span>
              <span>{{ goodsData1.author }}</span>
            </div>
            <div
              style="height: 41px; line-height: 41px; color: #787777"
              class="xiazai"
            >
              <span>{{ goodsData1.goldcoin }}币</span>

              <div style="display: flex; font-size: 12px;">
                <span style="display: flex; margin-right: 10px">
                  <i class="icon_view"></i>{{ goodsData1.look_number }}
                </span>
                <span style="display: flex; margin-right: 10px">
                  <i class="icon_dowm"></i>{{ goodsData1.download_number }}
                </span>
                <span style="display: flex;align-items: center;">
                  <img
                    src="../assets/img/like.png"
                    alt=""
                    height="18px"
                    style="vertical-align:middle; "
                  /><span>{{ goodsData1.fabulous }}</span></span
                >
              </div>
              <div class="tran_buttom">
                <span
                  @click="toDetail(goodsData1.id)"
                  style="cursor: pointer"
                  class="shoucang"
                >
                  <p>
                    <img
                      src="../assets/img/xiazai.svg"
                      alt=""
                      width="24px"
                      class="bottom_icon"
                    />
                  </p>
                  <span class="texthover">下载</span>
                </span>
                <span
                  @click="Shopping(goodsData1.id)"
                  style="
                    cursor: pointer;
                    border-left: 1px solid rgba(255, 255, 255, 0.5);
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                  "
                  class="shoucang"
                >
                  <p>
                    <img
                      src="../assets/img/gowuche.svg"
                      alt=""
                      width="24px;"
                      class="bottom_icon"
                    />
                  </p>
                  <span class="texthover">购物车</span>
                </span>
                <span
                  @click="click_Collection(goodsData1)"
                  style="
                    cursor: pointer;
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                  "
                  class="shoucang"
                >
                  <p>
                    <img
                      src="../assets/img/shocang.svg"
                      alt=""
                      width="24px"
                      class="bottom_icon"
                    />
                  </p>
                  <span class="texthover">收藏</span>
                </span>
                <span
                  @click="click_Collection(goodsData1)"
                  style="cursor: pointer"
                  class="shoucang"
                >
                  <p>
                    <img
                      src="../assets/img/dainz.png"
                      alt=""
                      width="24px"
                      class="bottom_icon"
                    />
                  </p>
                  <span class="texthover">点赞</span>
                </span>
              </div>
            </div>
          </el-col>
        </el-col>
      </el-col>
    </el-row>
    <!--分页 -->
    <div class="paginationBox" style="margin-top: 20px; text-align: center">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :current-page="currentPage"
        :page-size="per_page"
        :hide-on-single-page="true"
        @current-change="handleCurrentChange"
      ></el-pagination>
      <!--total 总条目数   current-page当前页数  page-size  per_page 每页多少条  -->
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-select v-model="value" placeholder="请选择收藏的文件夹">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        style="width: 320px; margin-right: 10px; margin-top: 20px"
        placeholder="请输入内容"
        maxlength="8"
        v-model="add_ProjectList_input"
      ></el-input>
      <el-button @click="addfolder">添加文件夹</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selection_box()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <login  v-show="showlogin" @closeDialog="closeDialog"></login> -->
    <el-dialog
      title="支付宝扫一扫可查看"
      :visible.sync="dialogVisible_zhifu"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="guanbi"
    >
      <div style="width: 100%; text-align: center">
        <vue-qr :text="zhifu_img" :size="200"></vue-qr>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>
<script>
import {
  add_fabulous,
  del_fabulous,
  user_fabulous,
  requestAddpush,
  requestCatalog,
  requestSort,
  requestdirectory_list,
  requestaccount_collect_add,
  requestdirectory_add,
  requestalipay,
  requestestalipay,
  requestcheck_orderstatus,
} from "../api/request";
// import login from "@/views/Login.vue";
import vueQr from "vue-qr";

export default {
  components: {
    // login,
    vueQr,
  },
  data() {
    return {
      catalog: ["库类型", "类别", "大类", "小类", "细分"],
      catalog1: ["类别", "大类", "小类", "细分"],
      catalog2: [],
      radio: "1",
      ischoose: true,
      isshow_1: 0,
      isshow_2: 0,
      isshow_3: 0,
      isshow_4: 0,
      isshow_5: 0,
      isshow_6: 0,
      level_0: [],
      level_1: [],
      level_2: [],
      level_3: [],
      level_4: [],
      level_5: [],
      price: 10,
      downloads: 20,
      browse: 30,
      dianzan: 40,
      isSelect: 2,
      goodsData: [],
      currentPage: 1,
      total: 1,
      per_page: 1,
      node_id_min: "",
      node_id_max: "",
      //收藏弹出框
      dialogVisible: false,
      //select选择框
      options: [],
      //选择框绑定
      value: "",
      //资源数据
      resource_data: "",
      showlogin: "",
      add_ProjectList_input: "",
      Child_list: "",
      current_select_level1_item: null,
      dialogVisible_zhifu: false,
      zhifu_img: "",
      setmimi: null,
      imgurl: require("../assets/img/dianzan.svg"),
      imgurlhover: require("../assets/img/dianzanhover.svg"),
      catalog_list: [],
      paent_data: [],
      conditiontwo: "0",
      shaixuandonghua: true,
      version: "0",
    };
  },
  watch: {
    // to , from 分别表示从哪跳转到哪，都是一个对象
    // to.path  ( 表示的是要跳转到的路由的地址 eg: /home );
    $route(to, from) {
      // console.log("111",this.$route.query.identification)
      if(this.$route.query.identification){
        let v = this.$route.query
        this.click_data(v.id,v.level,v.nodeid,v.sequence,v.parentnodeid)
        return;
      }
      this.isparent(1);

      if (to.query.sequence == 1) {
        this.catalog2 = this.catalog;
      } else {
        this.catalog2 = this.catalog1;
      }
    },

    version: function (newVal, oldVal) {
      let typeval = "";
      console.log(newVal)
      if (newVal.length < 0) {
        return false;
      }
      if (newVal.length >= 2) {
        typeval = 3;
      } else {
        typeval = newVal[0];
      }
      console.log(typeval,this.version);
      //单选监听实时获取
      let node_obj = {
        node_id_min: this.node_id_min,
        node_id_max: this.node_id_max,
        condition: this.radio,
        conditiontwo: parseInt(this.conditiontwo),
        sortType: this.sortType(),
        resource_type: this.$route.query.resource_type,
        version_type: typeval,
      };
      let _this = this;
      requestSort(node_obj).then((res) => {
        _this.total = res.ret.total;
        _this.per_page = res.ret.per_page;
        _this.currentPage = res.ret.current_page;
        _this.goodsData = res.ret.data;
        let token_nav = this.Account.GetToken();
        if (token_nav == null) {
          this.goodsData = res.ret.data;
          // this.$parent.logo_open();
          return;
        } else {
          let datas = {};
          user_fabulous(datas).then((e) => {
            for (let h = 0; h < e.ret.length; h++) {
              for (let i = 0; i < res.ret.data.length; i++) {
                if (e.ret[h] == res.ret.data[i].id) {
                  res.ret.data[i].webfabulous = 1;
                }
              }
            }
            this.goodsData = res.ret.data;
          });
        }
      });
    },
    radio: function (newVal, oldVal) {
      //单选监听实时获取
      let node_obj = {
        node_id_min: this.node_id_min,
        node_id_max: this.node_id_max,
        condition: newVal,
        conditiontwo: parseInt(this.conditiontwo),
        sortType: this.sortType(),
        resource_type: this.$route.query.resource_type,
        version_type: this.version
      };
      let _this = this;
      requestSort(node_obj).then((res) => {
        _this.total = res.ret.total;
        _this.per_page = res.ret.per_page;
        _this.currentPage = res.ret.current_page;
        _this.goodsData = res.ret.data;
        let token_nav = this.Account.GetToken();
        if (token_nav == null) {
          this.goodsData = res.ret.data;
          // this.$parent.logo_open();
          return;
        } else {
          let datas = {};
          user_fabulous(datas).then((e) => {
            for (let h = 0; h < e.ret.length; h++) {
              for (let i = 0; i < res.ret.data.length; i++) {
                if (e.ret[h] == res.ret.data[i].id) {
                  res.ret.data[i].webfabulous = 1;
                }
              }
            }
            this.goodsData = res.ret.data;
          });
        }
      });
    },
    conditiontwo: function (newVal, oldVal) {
      //单选监听实时获取
      let node_obj = {
        node_id_min: this.node_id_min,
        node_id_max: this.node_id_max,
        condition: this.radio,
        conditiontwo: parseInt(newVal),
        sortType: this.sortType(),
        resource_type: this.$route.query.resource_type,
        version_type: this.version
      };
      let _this = this;
      requestSort(node_obj).then((res) => {
        _this.total = res.ret.total;
        _this.per_page = res.ret.per_page;
        _this.currentPage = res.ret.current_page;
        _this.goodsData = res.ret.data;
        let token_nav = this.Account.GetToken();
        if (token_nav == null) {
          this.goodsData = res.ret.data;
          // this.$parent.logo_open();
          return;
        } else {
          let datas = {};
          user_fabulous(datas).then((e) => {
            for (let h = 0; h < e.ret.length; h++) {
              for (let i = 0; i < res.ret.data.length; i++) {
                if (e.ret[h] == res.ret.data[i].id) {
                  res.ret.data[i].webfabulous = 1;
                }
              }
            }
            this.goodsData = res.ret.data;
          });
        }
      });
    },
  },

  methods: {
    handleChange(event) {
      console.log(event);
    },
    click_link: function (e, level, nodeid, sequence) {
      window.location.href =
        "/ModeBase?level_0=" + e + "&parentnodeid=0&sequence=" + sequence;
    },
    guanbi() {
      clearTimeout(this.setmimi);
    },
    //点击收藏函数
    click_Collection(_this) {
      if (this.Account.GetSession() == null) {
        this.$router.push("SignIn");
        return;
      }
      this.dialogVisible = true;
      this.resource_data = _this;
      let data = {
        parent_id: 0,
      };
      requestdirectory_list(data).then((res) => {
        this.current_select_level1_item = res.ret[1];
        // 一级列表赋值
        let datas = {
          parent_id: res.ret[1].id,
        };
        requestdirectory_list(datas).then((res) => {
          this.Child_list = res.ret;
          this.options = [];
          //二级列表赋值
          for (let i = 0; i < res.ret.length; i++) {
            this.options.push({
              value: res.ret[i].node_id,
              label: res.ret[i].name,
            });
          }
        });
      });
    },

    isparent(index) {
      this.paent_data = [];
      if (this.$route.query.istop != "true") {
        this.click_data(
          this.$route.query.level_0,
          1,
          this.$route.query.parentnodeid,
          this.$route.query.sequence
        );
        return;
      }
      console.log(this.$route.query.istop, this.$route.query.level_0);
      if (this.MyShow.GetHomeCatalog() == null) {
        catalog_list().then((res) => {
          this.MyShow.SetHomeCatalog(JSON.parse(res));
          this.catalog_list = res;
        });
      } else {
        this.catalog_list = this.MyShow.GetHomeCatalog();
      }
      // this.jisunaparen(this.$route.query.level_0)

      for (let i = 0; i < this.catalog_list.length; i++) {
        if (this.catalog_list[i].id == this.$route.query.level_0) {
          // this.click_data(this.catalog_list[i].id, this.catalog_list[i].level, this.catalog_list[i].node_id, this.catalog_list[i].sequence, this.catalog_list[i].parent_node_id, this.catalog_list[i])

          this.jisunaparen(this.catalog_list[i].parent_id);
          this.paent_data.push(this.catalog_list[i]);
        }
      }

      for (let k = 0; k < this.paent_data.length; k++) {
        if (k == this.paent_data.length - 1) {
          this.click_data(
            this.paent_data[k].id,
            this.paent_data[k].level,
            this.paent_data[k].node_id,
            this.paent_data[k].sequence,
            this.paent_data[k].parent_node_id,
            this.paent_data[k]
          );
          return;
        }
        this.click_data(
          this.paent_data[k].id,
          this.paent_data[k].level,
          this.paent_data[k].node_id,
          this.paent_data[k].sequence,
          this.paent_data[k].parent_node_id,
          this.paent_data[k],
          false
        );
        console.log(this.paent_data[k], this.paent_data[k].name);
      }
    },
    jisunaparen(parent_id) {
      for (let i = 0; i < this.catalog_list.length; i++) {
        if (this.catalog_list[i].id == parent_id) {
          // console.log(this.catalog_list[i].name,this.catalog_list[i].id, this.catalog_list[i].level, this.catalog_list[i].node_id, this.catalog_list[i].sequence, this.catalog_list[i].parent_node_id, this.catalog_list[i])
          this.jisunaparen(this.catalog_list[i].parent_id);
          this.paent_data.push(this.catalog_list[i]);
          // this.click_data(this.catalog_list[i].id, this.catalog_list[i].level, this.catalog_list[i].node_id, this.catalog_list[i].sequence, this.catalog_list[i].parent_node_id, this.catalog_list[i])
          if (this.catalog_list[i].level == 1) {
            return;
          }
        }
      }
    },
    //加购
    Shopping(e) {
      let _this = this;
      requestAddpush(e).then(function (res) {
        if (res.code == 0) {
          _this.$message({
            message: "加入购物车成功",
            type: "success",
          });
        } else if (res.code == 5012) {
          _this
            .$confirm(
              "BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
            .then(() => {
              _this.$router.push("/VipPrivilge");
            })
            .catch(() => {
              _this.$message({
                type: "info",
                message: "已取消前往购买",
              });
            });
        }
      });
    },
    //添加文件夹
    addfolder() {
      if (this.add_ProjectList_input == "") {
        this.$message({
          message: "请填写项目名称",
          type: "warning",
        });
        return;
      }
      //获取最大的sequence
      let max_sequence = 0;
      for (let h = 0; h < this.Child_list.length; h++) {
        if (this.Child_list[h].sequence > max_sequence) {
          max_sequence = this.Child_list[h].sequence;
        }
        if (this.Child_list[h].name == this.add_ProjectList_input) {
          this.$message({
            message: "不可添加重复文件夹名称",
            type: "warning",
          });
          return;
        }
      }
      let sequence = max_sequence + 1;
      let node_idstr = this.common.retun_getnodeid(
        this.current_select_level1_item.node_id,
        2,
        sequence
      );
      let addData = {
        name: this.add_ProjectList_input,
        parent_id: this.current_select_level1_item.id,
        level: 2,
        sequence: sequence,
        node_id: node_idstr,
        parent_node_id: this.current_select_level1_item.node_id,
      };
      //添加请求
      let _this = this;
      requestdirectory_add(addData).then((res) => {
        if (res.code == 0) {
          _this.$message({
            message: "添加成功",
            type: "success",
          });
          _this.add_ProjectList_input = "";
        }
        this.dialogVisible_add = false;
        let data = {
          parent_id: 0,
        };
        requestdirectory_list(data).then((res) => {
          // 一级列表赋值
          let datas = {
            parent_id: res.ret[1].id,
          };
          requestdirectory_list(datas).then((res) => {
            _this.Child_list = res.ret;
            _this.options = [];
            //二级列表赋值
            for (let i = 0; i < res.ret.length; i++) {
              _this.options.push({
                value: res.ret[i].node_id,
                label: res.ret[i].name,
              });
            }
          });
        });
      });
    },
    selection_box() {
      if (this.value == "") {
        this.$message({
          type: "info",
          message: `请选择文件夹`,
        });
        return;
      }
      let data = {
        resource_id: this.resource_data.id,
        node_id: this.value,
      };
      requestaccount_collect_add(data).then((res) => {
        this.dialogVisible = false;
      });
    },
    click_data: function (
      e,
      level,
      nodeid,
      sequence,
      parentnodeid,
      item,
      isziyuan = true
    ) {
      console.log(e,level,nodeid,sequence,parentnodeid,item)
      if (this.$route.query.resource_type == 1) {
        this.shaixuandonghua = false;
      } else {
        this.shaixuandonghua = true;
      }
      console.log(item);
      if (nodeid == undefined) {
        nodeid = 0;
      }

      if (e == 0) {
        this.isshow_1 = this.$route.query.level_0;
      } else {
        if (isziyuan) {
          this.isshow_1 = this.$route.query.level_0;
          let list = [];
          if (level == 1) {
            list = this.common.retun_nodeid(0, level, sequence);
          } else {
            list = this.common.retun_nodeid(parentnodeid, level, sequence);
          }
          let node_obj = {
            node_id_min: list.Min,
            node_id_max: list.Max,
            condition: this.radio,
            conditiontwo: parseInt(this.conditiontwo),
            sortType: this.sortType(),
            paginate: 30,
            resource_type: this.$route.query.resource_type,
            version_type: this.version
          };
          this.node_id_min = list.Min;
          this.node_id_max = list.Max;

          requestSort(node_obj).then((res) => {
            this.total = res.ret.total;
            this.currentPage = res.ret.current_page;
            this.per_page = res.ret.per_page;
            let token_nav = this.Account.GetToken();
            if (token_nav == null) {
              this.goodsData = res.ret.data;
              // this.$parent.logo_open();
              return;
            } else {
              let datas = {};
              user_fabulous(datas).then((e) => {
                for (let h = 0; h < e.ret.length; h++) {
                  for (let i = 0; i < res.ret.data.length; i++) {
                    if (e.ret[h] == res.ret.data[i].id) {
                      res.ret.data[i].webfabulous = 1;
                    }
                  }
                }
                this.goodsData = res.ret.data;
              });
            }
          });
        }
      }
      let _this = this;
      requestCatalog(e).then((res) => {
        console.log(res)
        for (let i = 0; i < res.ret.length; i++) {
          res.ret[i].parentnodeid = nodeid;
        }
        if (res.ret.length != 0) {
          if (res.ret[0].level == 1) {
            _this.level_0 = res.ret;
            _this.isshow_1 = 0;
            _this.isshow_2 = 0;
            _this.isshow_3 = 0;
            _this.isshow_4 = 0;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_1 = [];
            _this.level_2 = [];
            _this.level_3 = [];
            _this.level_4 = [];
            _this.level_5 = [];
            _this.level_6 = [];
          } else if (res.ret[0].level == 2) {
            _this.level_1 = res.ret;
            _this.isshow_2 = 0;
            _this.isshow_3 = 0;
            _this.isshow_4 = 0;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_2 = [];
            _this.level_3 = [];
            _this.level_4 = [];
            _this.level_5 = [];
            _this.level_6 = [];
          } else if (res.ret[0].level == 3) {
            _this.isshow_2 = e;
            _this.level_2 = res.ret;
            _this.isshow_3 = 0;
            _this.isshow_4 = 0;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_3 = [];
            _this.level_4 = [];
            _this.level_5 = [];
            _this.level_6 = [];
          } else if (res.ret[0].level == 4) {
            _this.isshow_3 = e;
            _this.level_3 = res.ret;
            _this.isshow_4 = 0;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_4 = [];
            _this.level_5 = [];
            _this.level_6 = [];
          } else if (res.ret[0].level == 5) {
            _this.isshow_4 = e;
            _this.level_4 = res.ret;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_5 = [];
            _this.level_6 = [];
          } else if (res.ret[0].level == 6) {
            _this.isshow_5 = e;
            _this.level_5 = [];
            _this.level_5 = res.ret;
            _this.isshow_6 = 0;
          }
        } else {
          if (level == 2) {
            _this.isshow_2 = e;
            _this.isshow_3 = 0;
            _this.isshow_4 = 0;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_2 = [];
            _this.level_3 = [];
            _this.level_4 = [];
            _this.level_5 = [];
          } else if (level == 3) {
            _this.isshow_3 = e;
            _this.isshow_4 = 0;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_3 = [];
            _this.level_4 = [];
            _this.level_5 = [];
          } else if (level == 4) {
            _this.isshow_4 = e;
            _this.isshow_5 = 0;
            _this.isshow_6 = 0;
            _this.level_4 = [];
            _this.level_5 = [];
          } else if (level == 5) {
            _this.isshow_5 = e;
            _this.isshow_6 = 0;
            _this.level_5 = [];
          } else if (level == 6) {
            _this.isshow_6 = e;
          }
        }
      });
    },
    ifshow: function (e, level) {
      if (level == 1) {
        if (this.isshow_1 == e) {
          return true;
        }
      } else if (level == 2) {
        if (this.isshow_2 == e) {
          return true;
        }
      } else if (level == 3) {
        if (this.isshow_3 == e) {
          return true;
        }
      } else if (level == 4) {
        if (this.isshow_4 == e) {
          return true;
        }
      } else if (level == 5) {
        if (this.isshow_5 == e) {
          return true;
        }
      } else if (level == 6) {
        if (this.isshow_6 == e) {
          return true;
        }
      }
    },
    sort(e, lever) {
      this.isSelect = lever;
      if (lever == 1) {
        if (this.price == 10) {
          this.price = 11;
        } else {
          this.price = 10;
        }
      } else if (lever == 2) {
        if (this.downloads == 20) {
          this.downloads = 21;
        } else {
          this.downloads = 20;
        }
      } else if (lever == 3) {
        if (this.browse == 30) {
          this.browse = 31;
        } else {
          this.browse = 30;
        }
      } else {
        if (this.dianzan == 40) {
          this.dianzan = 41;
        } else {
          this.dianzan = 40;
        }
      }
      let node_obj = {
        node_id_min: this.node_id_min,
        node_id_max: this.node_id_max,
        condition: this.radio,
        conditiontwo: parseInt(this.conditiontwo),
        sortType: this.sortType(),
        resource_type: this.$route.query.resource_type,
        version_type: this.version
      };
      requestSort(node_obj).then((res) => {
        this.total = res.ret.total;
        this.per_page = res.ret.per_page;
        this.currentPage = res.ret.current_page;
        let token_nav = this.Account.GetToken();
        if (token_nav == null) {
          this.goodsData = res.ret.data;
          // this.$parent.logo_open();
          return;
        } else {
          let datas = {};
          user_fabulous(datas).then((e) => {
            for (let h = 0; h < e.ret.length; h++) {
              for (let i = 0; i < res.ret.data.length; i++) {
                if (e.ret[h] == res.ret.data[i].id) {
                  res.ret.data[i].webfabulous = 1;
                }
              }
            }
            this.goodsData = res.ret.data;
          });
        }
      });
    },
    handleCurrentChange(val) {
      if (this.$route.query.resource_type == 1) {
        this.shaixuandonghua = false;
      } else {
        this.shaixuandonghua = true;
      }
      window.scrollTo(0, 0);
      //分页数据处理
      let node_obj = {
        node_id_min: this.node_id_min,
        node_id_max: this.node_id_max,
        condition: this.radio,
        conditiontwo: parseInt(this.conditiontwo),
        sortType: this.sortType(),
        paginate: 30,
        resource_type: this.$route.query.resource_type,
        version_type: this.version
      };
      requestSort(node_obj, val).then((res) => {
        this.total = res.ret.total;
        this.per_page = res.ret.per_page;
        this.currentPage = res.ret.current_page;
        let token_nav = this.Account.GetToken();
        if (token_nav == null) {
          this.goodsData = res.ret.data;
          // this.$parent.logo_open();
          return;
        } else {
          let datas = {};
          user_fabulous(datas).then((e) => {
            for (let h = 0; h < e.ret.length; h++) {
              for (let i = 0; i < res.ret.data.length; i++) {
                if (e.ret[h] == res.ret.data[i].id) {
                  res.ret.data[i].webfabulous = 1;
                }
              }
            }
            this.goodsData = res.ret.data;
          });
        }
      });
    },
    //预览
    toDetail(id) {
      if (this.Account.GetSession() == null) {
        this.$router.push("SignIn");
        return;
      }
      window.open("/#/ModeResources?id=" + id);
      // this.$router.push({ path: "/ModeResources", query: { id } });
    },
    //类型
    sortType() {
      let sortType = "10";
      if (this.isSelect == 1) {
        sortType = this.price;
      } else if (this.isSelect == 2) {
        sortType = this.downloads;
      } else if (this.isSelect == 3) {
        sortType = this.browse;
      } else {
        sortType = this.dianzan;
      }
      return sortType;
    },
    //支付
    zhifu_fn() {
      let _this = this;
      if (this.$route.query.iszhifu != undefined) {
        let _this = this;
        let info = {
          total_amount: 2,
          subject: "测试支付",
          account_id: 0,
        };
        this.dialogVisible_zhifu = true;
        requestestalipay(info).then((res) => {
          _this.zhifu_img = res.ret.qrcode_url;
          this.setmimi = setInterval(function () {
            let info1 = {
              out_trade_no: res.ret.out_trade_no,
              total_amount: 2,
              subject: "测试支付",
              account_id: 0,
            };
            requestcheck_orderstatus(info1).then((res) => {
              if (res.code == 0) {
                _this.$message({
                  message: "恭喜你支付成功",
                  type: "success",
                });
                localStorage.setItem("zhifu", 0);
                _this.dialogVisible_zhifu = false;
                clearTimeout(_this.setmimi);
              }
            });
          }, 1000);
        });
      }
    },
    fabulous(item) {
      let token_nav = this.Account.GetToken();
      let _this = this;
      let resdata = this.goodsData;
      let data = {
        resource_id: item.id,
      };
      if (token_nav == null) {
        this.$router.push("SignIn");
        return;
      }
      if (item.webfabulous == 1) {
        del_fabulous(data).then((res) => {
          for (let i = 0; i < _this.goodsData.length; i++) {
            if (_this.goodsData[i].id == item.id) {
              resdata[i].webfabulous = 0;
              resdata[i].fabulous = resdata[i].fabulous - 1;
            }
          }
          _this.goodsData = resdata;
        });
      } else {
        add_fabulous(data).then((res) => {
          for (let i = 0; i < _this.goodsData.length; i++) {
            if (_this.goodsData[i].id == item.id) {
              resdata[i].webfabulous = 1;
              resdata[i].fabulous = resdata[i].fabulous + 1;
            }
          }
          _this.goodsData = resdata;
          console.log(_this.goodsData);
        });
      }
    },
  },
  mounted() {
    // this.click_data(0,1);
    // if(this.$route.query.identification){
    //   let v = this.$route.query
    //   this.click_data(v.id,v.level,v.node_id,v.sequence,v.parent_node_id)
    // }
    if (this.$route.query.level_0 != 0) {
      console.log("111")
      // this.click_data(this.$route.query.level_0, 1, 0, this.$route.query.sequence);
      this.isparent(2);
    }
    if (this.$route.query.sequence == 1) {
      this.catalog2 = this.catalog;
    } else {
      this.catalog2 = this.catalog1;
    }
    this.zhifu_fn();
    if (this.$route.query.resource_type == 1) {
      this.shaixuandonghua = false;
    } else {
      this.shaixuandonghua = true;
    }
  },
};
</script>
<style scoped>
@import "../style/common.css";

.shaixuan {
  background: #fff;
}

.shaixuan:last-child {
  border-bottom: none;
}

.active_sort {
  color: #007bff;
}

.shaixuan > div {
  line-height: 50px;
  border-bottom: 1px solid #ccc;
}

.choose {
  color: #b6492c !important;
}

/* .hoverbox:hover{background-color:#cad8ed;} */

.shoucang {
  display: none;
}

.xiazai {
  justify-content: space-between;
  display: flex;
}

.texthover:hover {
  color: white;
}

.bdname {
  display: block;
  /* 当前元素本身是inline的，因此需要设置成block模式 */
  white-space: nowrap;
  /* 因为设置了block，所以需要设置nowrap来确保不换行 */
  overflow: hidden;
  /* 超出隐藏结合width使用截取采用效果*/
  text-overflow: ellipsis;
  /* 本功能的主要功臣，超出部分的剪裁方式 */
  -o-text-overflow: ellipsis;
  /* 特定浏览器前缀 */
  text-decoration: none;
  /* 无用 */
  float: left;
  width: 60%;
}

.hoverbox:nth-child(5n) {
  margin-right: 0px;
}

.active_sort {
  color: #007bff;
}

.hoverbox {
  width: 265px;
  height: 266px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 12px;
  margin-bottom: 50px;
  padding: 0 !important;
}

.hoverbox .box {
  background: #fff;
  padding: 0 !important;
}

.box.imgbox img {
  transform: scale(0.5);
}

.hoverbox:hover {
  box-shadow: 0px 0px 6px 0px rgba(196, 62, 27, 0.75);
}

.hoverbox:hover .tran_buttom {
  bottom: 0px;
}

.box .imgbox {
  /* transform: translateY(calc(50% - 55px)); */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 183px;
  overflow: hidden;
  cursor: pointer;
}

.xiazai {
  justify-content: space-between;
  display: flex;
  margin-left: 6px;
  margin-right: 6px;
}

.box:hover .imgbox {
  overflow: hidden;
}

.box:hover .imgbox img {
  transform: scale(1.2);
  transition: 1s;
}

.texthover {
  display: block;
  color: white;
  text-align: center;
}

.list_top_data {
  height: 41px;
  line-height: 41px;
  border-bottom: 1px solid #ebebeb;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #787777;
  justify-content: space-between;
  display: flex;
  margin-right: 6px;
  margin-left: 6px;
}

.listbuttom_data {
  position: relative;
  background-color: #f8f8f8;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.tran_buttom {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -83px;
  left: 0px;
  transition: 0.5s;
}

.shoucang {
  width: 66px;
  height: 83px;
  background-color: rgba(196, 62, 28, 0.9);
  display: block;
}

.shoucang:hover {
  background-color: rgba(255, 146, 98, 0.9);
}

.bottom_icon {
  margin: 15px auto 0 auto;
  display: block;
}

.text_over {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box_img {
  height: 183px !important;
}

>>>.el-radio {
  margin-right: 15px !important;
}

>>> .el-radio__input.is-checked .el-radio__inner {
  background: #b6492c;
  border-color: #b6492c;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #b6492c;
  border-color: #b6492c;
}

>>> .el-radio__input.is-checked + .el-radio__label {
  color: #b6492c;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #b6492c;
}
>>> .el-link.el-link--default:hover,
.el-link.is-underline:hover:after {
  color: #b6492c;
  border-bottom-color: #b6492c;
}
.donghua {
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}
.donghua > p {
  height: 30px;
  background: url(../assets/img/donghuaicon.svg) no-repeat center center;
  background-size: 100% 100%;
}
.donghua:hover > p {
  background: url(../assets/img/donghuaiconhover.svg) no-repeat center center;
  background-size: 100% 100%;
}

.wendang {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.wendang > p {
  height: 30px;
  background: url(../assets/img/wendangicon.svg) no-repeat center center;
  background-size: 100% 100%;
}
.wendang:hover > p {
  background: url(../assets/img/wendangiconhover.svg) no-repeat center center;
  background-size: 100% 100%;
}
.icon_view {
  margin-top: 10px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: block;
  background: url(../assets/img/view.svg) no-repeat center center;
  background-size: 100% 100%;
}

.icon_dowm {
  margin-top: 10px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: block;
  background: url(../assets/img/dowm.svg) no-repeat center center;
  background-size: 100% 100%;
}
.neizhishaixuan {
  cursor: pointer;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #b6492c;
  border-color: #b6492c;
}
</style>
